<template>
  <div class="page-container">
    <md-app md-mode="reveal">
      <md-app-toolbar class="md-primary">
        <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <span>
          <md-avatar class="md-xsmall-hide md-small-hide">
            <img v-if="!logo" src="@/assets/images/LOGO_DESPACHO_CONTABLE.png" alt="logo">
            <img v-if="logo" :src="'data:image/png;base64,' +logo" alt="logo">
          </md-avatar>
        </span>
        <span class="md-title">
          <span class="md-xsmall-hide md-small-hide" v-if="!name_establecimiento()"
          :title="office">{{name_office()}}</span>
          <span class="md-xsmall-hide" :title="company.name"
          v-if="company"> > {{name_contribuyente()}} </span>
          <span v-if="store">
            <span class="md-xsmall-hide"> > </span>
            <span class="truncate-text" :title="store.name">{{name_establecimiento()}} </span>
          </span>
        </span>
        <div class="md-toolbar-section-end">
          <md-menu md-size="small" md-direction="bottom-end">
            <md-button class="md-icon-button" md-menu-trigger>
              <md-icon>more_vert</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item @click="go_to(`/profile`)">
                <span>Mis datos</span>
                <md-icon>person</md-icon>
              </md-menu-item>
              <md-menu-item @click.prevent="Logout()">
                <span>Salir</span>
                <md-icon>exit_to_app</md-icon>
             </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="menuVisible" class="md-scrollbar">
        <md-toolbar class="md-transparent" md-elevation="0">Menú</md-toolbar>
        <md-list>
          <md-list-item >
            <md-icon @click="menuVisible=!menuVisible">
              <md-tooltip md-direction="top">Inicio</md-tooltip>
              <router-link to="/"> home</router-link>
            </md-icon>
            <span class="md-list-item-text" @click="menuVisible=!menuVisible">
              <router-link to="/">Inicio</router-link></span>
          </md-list-item>
          <md-list-item>
            <md-icon @click="menuVisible=!menuVisible">
              <md-tooltip md-direction="top">Compradores</md-tooltip>
              <router-link to="/customers"> person</router-link>
            </md-icon>
            <span class="md-list-item-text" @click="menuVisible=!menuVisible">
              <router-link to="/customers">Compradores</router-link>
            </span>
          </md-list-item>
          <md-list-item>
            <md-icon @click="menuVisible=!menuVisible">
              <md-tooltip md-direction="top">Proveedores</md-tooltip>
              <router-link to="/providers">group</router-link>
            </md-icon>
            <span class="md-list-item-text" @click="menuVisible=!menuVisible">
              <router-link to="/providers">Proveedores</router-link>
            </span>
          </md-list-item>
          <md-list-item>
            <md-icon @click="menuVisible=!menuVisible">
              <md-tooltip md-direction="top">Bancos</md-tooltip>
              <router-link to="/banks">account_balance</router-link>
            </md-icon>
            <span class="md-list-item-text" @click="menuVisible=!menuVisible">
              <router-link to="/banks">Bancos</router-link>
            </span>
          </md-list-item>
          <md-list-item>
            <md-icon @click="menuVisible=!menuVisible" class="material-icons-outlined">
              <md-tooltip md-direction="top">Cuentas Contables</md-tooltip>
              <router-link to="/cuentas_contables">widgets</router-link>
            </md-icon>
            <span class="md-list-item-text" @click="menuVisible=!menuVisible">
              <router-link to="/cuentas_contables">Cuentas Contables</router-link>
            </span>
          </md-list-item>
          <md-list-item>
            <md-icon @click="menuVisible=!menuVisible" class="material-icons-outlined">
              <md-tooltip md-direction="top">Usuario</md-tooltip>
              <router-link to="/employes">business_center</router-link>
            </md-icon>
            <span class="md-list-item-text" @click="menuVisible=!menuVisible">
              <router-link to="/employes">Usuarios</router-link>
            </span>
          </md-list-item>
          <md-list-item>
            <md-icon @click="menuVisible=!menuVisible" class="material-icons-outlined">
              <md-tooltip md-direction="top">Configuración base</md-tooltip>
              <router-link to="/base_config">tune</router-link>
            </md-icon>
            <span class="md-list-item-text" @click="menuVisible=!menuVisible">
              <router-link to="/base_config">Configuración base</router-link>
            </span>
          </md-list-item>
          <md-list-item>
            <md-icon @click="menuVisible=!menuVisible" class="material-icons-outlined">
              <md-tooltip md-direction="top">Años fiscales</md-tooltip>
              <router-link to="/fiscal_year_list">date_range</router-link>
            </md-icon>
            <span class="md-list-item-text" @click="menuVisible=!menuVisible">
              <router-link to="/fiscal_year_list">Años fiscales</router-link>
            </span>
          </md-list-item>
        </md-list>
      </md-app-drawer>
      <md-app-content>
        <transition name="fade" mode="out-in" :duration="{ enter: 300, leave: 800 }">
        <router-view/>
      </transition>
      </md-app-content>
    </md-app>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div class="phone-viewport" >
      <md-bottom-bar md-type="shift" v-if="store">
        <md-bottom-bar-item id="bottom-bar-item-purchase"
          v-if="$route.name === 'Periodo'"
          md-label="Compra" md-icon="local_mall"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-purchase"
          v-if="$route.name != 'Periodo'"
          @click="go_to(`/period`)"
          md-label="Compra" md-icon="local_mall"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-sell"
          v-if="$route.name === 'periodo_sale'"
          md-label="Ventas" md-icon="attach_money"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-sell"
          v-if="$route.name != 'periodo_sale'"
          @click="go_to(`/period-sale`)"
          md-label="Ventas"
           md-icon="attach_money"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-inventario"
        v-if="$route.name != 'establecimientos'"
        @click="go_to(`/stores/${company.id}`)"
          md-label="Establecimientos" md-icon="store_mall_directory"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-inventario"
        v-if="$route.name === 'establecimientos'"
          md-label="Establecimientos" md-icon="store_mall_directory"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-banco"
        v-if="$route.name != 'movimientos_bancarios'"
        @click="go_to(`/movimientos_bancarios/`)"
          md-label="Banco" md-icon="account_balance"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-banco"
        v-if="$route.name === 'movimientos_bancarios'"
          md-label="Banco" md-icon="account_balance"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-partidas"
        v-if="$route.name != 'partidas'"
        @click="go_to(`/partidas/`)"
          md-label="Partidas" md-icon="receipt"></md-bottom-bar-item>

        <md-bottom-bar-item id="bottom-bar-item-partidas"
        v-if="$route.name === 'partidas'"
          md-label="Partidas" md-icon="receipt"></md-bottom-bar-item>
      </md-bottom-bar>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Default',
  created() {
    const officeName = this.get_data_storage_raw('office_name');
    const officeLogo = this.get_data_storage_raw('office_logo');
    if (officeLogo) {
      this.logo = officeLogo;
      // const elIcon = document.querySelector("link[rel~='icon']");
      // elIcon.setAttribute('href', officeLogo);
    }
    if (officeName) {
      this.office = officeName;
    }
  },
  data() {
    return {
      menuVisible: false,
      office: 'DESPACHO CONTABLE & ASOCIADOS',
      logo: null,
    };
  },
  methods: {
    name_office() {
      if (!this.company) return this.office;
      if (this.company.name.length > 30) {
        return `${this.office.slice(0, 10)} ${'...'}`;
      }
      if (this.company.name.length < 30) {
        return this.office;
      }
      return this.office;
    },
    name_contribuyente() {
      if (!this.company) return false;
      if (this.company.name.length > 30) {
        return `${this.company.name.slice(0, 15)} ${'...'}`;
      }
      if (this.company.name.length < 30) {
        return this.company.name;
      }
      return '';
    },
    get_data_storage_raw(name) {
      return localStorage.getItem(name);
    },
    name_establecimiento() {
      if (!this.store) return false;
      if (this.store.name.length > 30) {
        return `${this.store.name.slice(0, 20)} ${'...'}`;
      }
      if (this.store.name.length < 30) {
        return this.store.name;
      }
      return '';
    },
    go_to(url) {
      this.$router.push(url);
    },
    Logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      }).catch((error) => {
        console.error(error);
        this.$swal.fire('Error!', 'No fue posible finalizar la sesión.', 'error');
      });
    },
  },
  computed: {
    company() {
      return this.$store.state.company_obj;
    },
    store() {
      return this.$store.state.store_obj;
    },
  },
};
</script>

<style lang="scss" >
  @import "~vue-material/dist/theme/engine"; // Import the theme engine
  @include md-register-theme("default", (
    primary: md-get-palette-color(teal, 500), // The primary color of your application
    accent: md-get-palette-color(purple, 200),// The secondary color of your brand
    theme: light // This can be dark or light
  ));
  @import "~vue-material/dist/theme/all"; // Apply the theme
</style>
<style lang="css" scoped>
  .md-app {
    border: 1px solid rgba(#000, .12);
  }
  .page-container {
    min-height: 300px;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(#000, .12);
  }
  .md-drawer {
    width: auto;
    max-width: calc(100vw - 125px);
  }
  .phone-viewport {
    align-items: flex-end;
    position: fixed;
    bottom: 0px;
    width:100%;
  }
  .md-app-content {
    padding: 5px;
  }
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .fade-enter-active {
    transition: all .5s ease-out;
  }
  .fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(20px);
    opacity: 0;
  }
  .md-card {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .truncate-text{
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<style lang="css" >
  @media (max-width: 600px) {
    .md-dialog-fullscreen {
      overflow-y: auto !important;
    }
  }
</style>
